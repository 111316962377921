export default {
  search(state, {keyword}) {
    state.keyword = keyword
  },
  isLogin(state, {isLogin}) {
    state.isLogin = isLogin
  },
  changeDialogShow(state, {loginDialogVisible}) {
    state.loginDialogVisible = loginDialogVisible
  },
  gerUserInfo(state, {user}) {
    state.id = user.id
    state.address = user.address
    state.avatar = user.avatar
    state.birthday = user.birthday
    state.nickname = user.nickname
    state.telephone = user.telephone
    state.email = user.email
    state.loongsonAccount = user.loongsonAccount
  },
  setToken(state, {token}) {
    state.token = token
  },
  setCateId(state, {cateId}) {
    state.cateId = cateId
  },
  isLastPage(state, {isLastPage}) {
    state.isLastPage = isLastPage
  },
  lastPage(state, {lastPage}) {
    state.lastPage = lastPage
  },
  orderType(state, {orderType}) {
    state.orderType = orderType
  },
  catePageIndex(state, {catePageIndex}) {
    state.catePageIndex = catePageIndex
  },

}

