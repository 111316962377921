/**
 * @Description 龙芯应用公社
 * @author Loongson.Quzhenhong
 * @date 21-7-13
 */

/*
 * 封装网络请求
 */
import axios from 'axios'
import Vue from 'vue'
import {
  message
} from '../resetMessage';
import conf from "@/utils/conf";
import NProgress from "nprogress"

axios.defaults.baseURL = conf.getBaseUrl()

axios.defaults.timeout = 10000 * 6 * 5;
// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {
  NProgress.start()
  // 为请求头对象，添加token验证的Authorization字段
  config.headers["Access-Token"] = localStorage.getItem('token')
  config.headers["Source-Type"] = "web"
  config.headers["Client-Version"] = "2"
  return config
})
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
}, err => {
  if (err.message.includes('timeout')) {
    //超时处理
    message.error('请求超时')
  }
})

let a = 0
export default function ajax(url, data = {}, type = 'POST') {
  a++
  return new Promise((resolve, reject) => {
    let promise
    if (type === 'GET') {
      let dataStr = ''
      Object.keys(data).forEach(key => {
        dataStr += key + '=' + data[key] + '&'
      })
      if (dataStr) {
        // slice---（数组、字符串的方法）第一个参数是从什么位置开始，第二个参数是保留到什么位置（第二个参数指定就不被包含在内）
        //     ---不会影响原字符串、数组，将截取的数组字符串作为返回值返回
        dataStr = dataStr.slice(0, -1)
        url = url + '?' + dataStr
      }

      promise = axios.get(url)
    } else {
      promise = axios.post(url, data)
    }
    promise.then(response => {
      a--
      resolve(response)
      if (a === 0) {
        //message.success('加载成功')
      }
    }).catch(error => {
      //reject(error)
      // console.log(error)
      message.error('网络错误')
    })
  })
}
