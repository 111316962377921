import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//导入element-ui
import './plugins/element.js'
import './theme/index.css'
//引入全局的css
import './assets/global.css'
//引入reset.css
import './assets/reset.css'
//引入字体图标的css
import './assets/fonts/iconfont.css'
//引入工具文件
import './utils/utils'
Vue.config.productionTip = false

// 引入 mavon-editor
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
Vue.use(mavonEditor);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
