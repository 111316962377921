<template>
  <div id="app">

    <router-view></router-view>
    <!--<el-backtop target="#app" :right='40'　 :bottom='100' :visibility-height='100'><div class="con">UP</div></el-backtop>-->
  </div>
</template>
<script>
  import {getPersonal} from '@/utils/network'

  export default {
    name: "App",
    async created() {
      const token = localStorage.getItem('token')
      if (token) {
        const {data: res} = await getPersonal({})
        // console.log(res)
        if (res.code !== 200) return localStorage.removeItem('token')
        this.$store.dispatch('setToken', {
          token,
        })
        this.$store.dispatch('gerUserInfo', {
          user: res.data
        })
      }
      this.handleVuex()

    },
    methods:{
      handleVuex() {
        //在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem("keyword") ) {
          this.$store.state.keyword = sessionStorage.getItem("keyword")
        }
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        window.addEventListener("beforeunload",()=>{
          sessionStorage.setItem("keyword",this.$store.state.keyword)
        })
      }
    }
  }
</script>
<style scoped>

</style>
