import Vue from 'vue'
//格式化时间的插件
Vue.filter('dataFormat', function (originVal) {
  if (originVal) {
    const dt = new Date(originVal)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')

    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    // yyyy-mm-dd hh:mm:ss ${hh}:${mm}:${ss}
    return `${y}/${m}/${d} `
  }
})
Vue.filter('dataFormatDetail', function (originVal) {
  if (originVal) {
    const dt = new Date(originVal)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')

    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    //yyyy-mm-dd hh:mm:ss
    return `${y}-${m}-${d}  ${hh}:${mm}:${ss} `
  }
})
Vue.filter('commentFormatName', function (originVal) {
  if (originVal) {
    var reg = /^1[3|4|5|7|8|9]\d{9}$/;
    if (reg.test(originVal)) {
      originVal = originVal.slice(0,3)+"****"+originVal.slice(7)
    }
    return originVal
  }
})
import {reqTelCode} from "@/utils/network";

//挂载中央事件
Vue.prototype.$bus = new Vue()

