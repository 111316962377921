import Vue from 'vue'
import VueRouter from 'vue-router'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(VueRouter)
Vue.use(Viewer);
Viewer.setDefaults({
  zIndex: 9999,
  inline: false, // 启用 inline 模式
  button: true, // 显示右上角关闭按钮
  navbar: true, // 显示缩略图导航
  title: false, // 显示当前图片的标题
  toolbar: false
});

const routes = [
  {
    path: '*', component: () => import('@/components/NotFound.vue')
  },
  {
    path: '', redirect: '/home'
  },
  {
    path: '/middle', component: () => import('@/components/login/Middle.vue')
  },
  {
    path: '/ErrorPage', component: () => import('@/views/errorPage/ErrorPage.vue')
  },
  {
    path: '/reset', component: () => import('@/views/resetPage/PageOne.vue')
  },
  {
    path: '/home', component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/home', component: () => import('@/views/home/First.vue')
      },
      {
        path: '/cate', component: () => import('@/views/cateSoft/CateSoft.vue')
      },
      {
        path: '/hot', component: () => import('@/views/hotSoft/HotSoft.vue')
      },
      {
        path: '/install', component: () => import('@/views/installSoft/InstallSoft.vue')
      },
      {
        path: '/speak', component: () => import('@/views/hotSpeak/HotSpeak.vue')
      },
      {
        path: '/new', component: () => import('@/views/newSoft/NewSoft.vue')
      },
      {
        path: '/email', component: () => import('@/views/contact/Contact')
      },
      {
        path: '/reset', component: () => import('@/views/resetPage/PageOne.vue')
      },
      {
        path: '/profile', component: () => import('@/views/profile/Profile.vue')
      },
      {
        path: '/detail/:id', component: () => import('@/views/detailPage/DetailPage.vue')
      },
      {
        path: '/detailPost/:id', component: () => import('@/views/detailPage/PostDetail.vue')
      },
      {
        path: '/search', component: () => import('@/views/searchContent/SearchContent.vue')
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
/* 重定向不报错 */
/*const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}*/
router.beforeEach((to, from, next) => {

  if (to.path === '/profile') {
    const token = localStorage.getItem('token')
    if (!token) return next('/home')
  }
  next()
})
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
let originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location,resolve,reject){
  if(resolve&&reject){
    originPush.call(this,location,resolve,reject);
  }else{
    originPush.call(this,location,()=>{},()=>{});
  }
}
export default router
