import ajax from "./ajax"

//应用详情
export const getSoftDetail = data => ajax('community/app', data)
//应用分类
export const getCateSoft = data => ajax('community/categories', data)
//装机必备
export const getInstallSoft = data => ajax('community/categories/must', data)
//应用搜索接口
export const getSearcherSoft = data => ajax('community/searcher', data)
//帖子搜索接口
export const getArticleSearcherSoft = data => ajax('community/article/searcher', data)
//热门帖子
export const getHotSpeak = data => ajax('community/article/hot', data)
//最新上架
export const getNewSoft = data => ajax('community/app/latests', data)
//热门推荐
export const getHotSoft = data => ajax('community/app/hot', data)
//帖子详情
export const getDetailSpeak = data => ajax('community/article', data)
//留言
export const leaveMessage = data => ajax('community/advice', data)
//nav 列表
export const getNavList = data => ajax('community/categories/list', data)
//登录
export const reqLogin = data => ajax('user/signIn', data)
//手机验证码登录
export const reqPhoneLogin = data => ajax('user/telephone', data)
//注册
export const reqRegister = data => ajax('user/signUp', data)
//获取验证码
export const reqImgCode = data => ajax('user/imageAuthCode', data, "GET")
//获取手机验证码
export const reqTelCode = data => ajax('user/telephoneAuthCode', data, "GET")
//获取评论列表
export const getComments = data => ajax('community/app/comments', data)
//发表评论
export const setComments = data => ajax('community/app/comments/insert', data)
//修改个人资料   personal/avatar
export const changePersonal = data => ajax('personal', data)
//获取个人资料
export const getPersonal = data => ajax('user', data, 'GET')
//评分
export const rate = data => ajax('community/botscort/proportion', data)
//修改密码
export const resetPassword = data => ajax('user/password_reset', data)
//软件详情评论点赞
export const DetailRateZan = data => ajax('community/app/comments/update', data)
//帖子详情获取评论
export const SpeakDetailGetComments = data => ajax('community/article/comments', data)
//帖子详情发表评论
export const SpeakDetailSetComments = data => ajax('community/article/comments/insert', data)
//帖子详情评论点赞
export const SpeakDetailRateZan = data => ajax('community/article/comments/update', data)
// 软件用户评分
export const softRate = data => ajax('community/botscort/insert', data)
// 评分验证
export const getRateToken = data => ajax('community/botscort/select', data)
// 评分比例
export const getRateProportion = data => ajax('community/botscort/proportion', data)
// 帖子点赞
export const speakFeelGood = data => ajax('community/comment/article', data)
// 软件下载
export const download = data => ajax('community/download', data)
// 微信登录
export const weiLogin = data => ajax('oauth/render', data, 'GET')
