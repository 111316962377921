export default {
  search({commit}, {keyword}) {
    commit('search', {keyword})
  },
  isLogin({commit}, {isLogin}) {
    commit('isLogin', {isLogin})
  },
  changeDialogShow({commit}, {loginDialogVisible}) {
    commit('changeDialogShow', {loginDialogVisible})
  },
  gerUserInfo({commit}, {user}) {
    commit('gerUserInfo', {user})
  },
  setToken({commit}, {token}) {
    commit('setToken', {token})
  },
  setCateId({commit}, {cateId}) {
    commit('setCateId', {cateId})
  },
  isLastPage({commit}, {isLastPage}) {
    commit('isLastPage', {isLastPage})
  },
  lastPage({commit}, {lastPage}) {
    commit('lastPage', {lastPage})
  },
  orderType({commit}, {orderType}) {
    commit('orderType', {orderType})
  },
  catePageIndex({commit}, {catePageIndex}) {
    commit('catePageIndex', {catePageIndex})
  }


}
