import Vue from 'vue'
import Vuex from 'vuex'
import mutations from "./mutations"
import getters from "./getters"
import actions from "./actions"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    //搜索关键词内容
    keyword: '',
    //登录注册切换标识
    isLogin: true,
    //登录注册弹框的控制
    loginDialogVisible: false,
    //用户信息
    id: '',
    address: '',
    avatar: '',
    birthday: '',
    nickname: '',
    telephone: '',
    email: '',
    loongsonAccount: '',
    token: '',
    lastPage:'',
    catePageIndex:'',
    orderType:'',
    isLastPage: false,
    cateId: ''


  },
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {},
  //解决网页刷新时，vuex数据丢失的问题
  plugins: [createPersistedState({
      storage: window.sessionStorage,
      reducer(val) {
          return { // 只储存state中的catePageIndex
              catePageIndex: val.catePageIndex,
              orderType:val.orderType
          }
      }
  })]
})
